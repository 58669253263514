
import React, { useState } from 'react';
import BaseUrl from '../../baseurl/BaseUrl';
import { PostApiWithOutToken } from "../../Helper/helper";
import {  useNavigate } from 'react-router-dom'
import Spinner from 'react-bootstrap/Spinner';
import Swal from "sweetalert2";


const Simplelogin = () => {
    const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [loder, setloader] = useState(false);
  // const [showA, setShowA] = useState(false);
  // const toggleShowA = () => setShowA(!showA);
//   const [, setShowToast] = useState(false);
 
  // console.log('email==============>',email)
  const navigate = new useNavigate();
  const onSubmit = () => {
    setloader(true)
    // console.log('rewre============w')
    const formData = new FormData();
    formData.append("email", email);
    formData.append("password", password);

    PostApiWithOutToken(`${BaseUrl.baseUrl}admin/login`, formData)
      .then((data) => {
        // console.log(data,"aaaaaa");
        // console.log(data.data.user.email, "minhaj");
        // setShowToast(true);
        // toast(data.data.message);
        
       
        
          
           Swal.fire({
            title: "success",
            text: data.data.message,
            icon: "success",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });

          localStorage.setItem("token", data.data.token);
          localStorage.setItem("email", data.data.user.email);
          localStorage.setItem("fname", data.data.user.first_name);
        //   localStorage.setItem("lname", data.data.user.last_name);
          // localStorage.setItem("lname", data.data.user.last_name);
        //   localStorage.setItem("heightfeet", data.data.user.personal_detail.height_feet);
        //   localStorage.setItem("heightinches", data.data.user.personal_detail.height_inches);
        //   localStorage.setItem("lname", data.data.data.lname);
        //   localStorage.setItem("id", data.data.data.id);
        //   localStorage.setItem("img", data.data.data.profile);
        navigate("/dashboard");
         

          setloader(false)
        
          // console.log("name", localStorage.getItem("name"));
         
      
          
          
          // alert(data.data);
          
    
         
        
      })
      .catch((error) => {
        console.log("mmm", error);
        setloader(false)

        Swal.fire({
            title: "error",
            text: error,
            icon: "error",
            confirmButtonColor: "#29BF12",
            timer: 1500,
          });
        // setShowToast(true);
        // toast(error.message);
      });

    // setShow(!show)
  };

    


   
    return (
        <div className="">
            <div className="content-wrapper">
                <div className="content-header row">
                </div>
                <div className="content-body">
                    <section className="flexbox-container">
                        <div className="col-12 d-flex align-items-center justify-content-center">
                            <div className="col-md-4 col-10 box-shadow-2 p-0">
                                <div className="card border-grey border-lighten-3 m-0">
                                    <div className="card-header border-0">
                                        <div className="card-title text-center">
                                            <div className="p-1">
                                                <img src="../../../app-assets/images/logo/logos.png" alt="branding logo" style={{width:'100PX'}}/>
                                            </div>
                                        </div>
                                        <h6 className="card-subtitle line-on-side text-muted text-center font-small-3 pt-2">
                                            <span>Login with PSC</span>
                                        </h6>
                                    </div>
                                    <div className="card-content">
                                        <div className="card-body">
                                            <form className="form-horizontal form-simple">
                                                <fieldset className="form-group position-relative has-icon-left mb-0">
                                                    <input type="text" className="form-control form-control-lg input-lg" id="user-name" placeholder="Your Username" required
                                                     onChange={(e)=>{setemail(e.target.value)}}
                                                    />
                                                    <div className="form-control-position">
                                                        <i className="ft-user" />
                                                    </div>
                                                </fieldset>
                                                <fieldset className="form-group position-relative has-icon-left">
                                                    <input type="password" className="form-control form-control-lg input-lg" id="user-password" placeholder="Enter Password" required  style={{marginTop:'20PX'}}
                                                    
                                                    onChange={(e)=>{setpassword(e.target.value)}}
                                                    />
                                                    <div className="form-control-position">
                                                        <i className="la la-key"  style={{marginTop:'30PX'}}/>
                                                    </div>
                                                </fieldset>
                                                <div className="form-group row">
                                                    <div className="col-md-6 col-12 text-center text-md-left">
                                                        <fieldset>
                                                            <input type="checkbox" id="remember-me" className="chk-remember" />
                                                            <label htmlFor="remember-me"> Remember Me</label>
                                                        </fieldset>
                                                    </div>
                                                    <div className="col-md-6 col-12 text-center text-md-right"><a href="recover-password.html" className="card-link">Forgot Password?</a></div>
                                                </div>
                                                <button type="button" className="btn btn-info btn-lg btn-block"  onClick={onSubmit}>
                                                {loder !== false? 
                      <Spinner animation="border"  />:'Login'  
                      
                     }
                     {/* <i className="ft-unlock" />  */}
                                                
                                                </button>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        {/* <div className>
                                            <p className="float-sm-left text-center m-0"><a href="recover-password.html" className="card-link">Recover password</a></p>
                                            <p className="float-sm-right text-center m-0">New to Moden Admin? <a href="register-simple.html" className="card-link">Sign Up</a></p>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>

    )
}

export default Simplelogin